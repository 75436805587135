import React, { FC } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const LarixPage: FC = () => (
  <Layout>
    <SEO title="Rússalerki" />
    <h3>Rússalerki (<i>Larix sukaczewii</i> Dylis)</h3>
    <p>
      Harðgerð og hraðvaxta frumherjatrjátegund. Æskuvöxtur er oftast mjög góður
      ef rétt land er valið og jarðvinnsla, þar sem hún á við.
    </p>
    <p>
      Rússalerki er dæmigerð meginlandstrjátegund sem þrífst illa í hafrænu
      loftslagi. Þrif rússalerkis eru því bundin við Norðurland og
      Fljótsdalshérað. Í öðrum landshlutum á rússalerki erfitt uppdráttar þó á
      því finnist stöku undantekningar. Því er ekki mælt með gróðursetningu á
      rússalerki utan Norðurlands og Fljótsdalshéraðs.{" "}
    </p>
    <p>
      Þolir trjátegunda best rýrt og lítt gróið land þó að vöxtur sé oft betri á
      miðlungsfrjósömu landi. Hentar því ágætlega til landgræðslu inn til
      landsins norðan heiða. Þolir heldur illa svæði þar sem hætta er á
      næturfrostum s.s. sléttur og dalbotna og einnig land með þéttum og köldum
      jarðvegi.
    </p>
    <p>
      Bestu svæðin á Íslandi eru: Fljótsdalshérað innanvert, Langidalur og
      inndalir hans í Austur-Húnavatnssýslu, innanverður Skagafjörður,
      Eyjafjörður og S.-Þingeyjarsýsla.
    </p>
    <p>
      Fjölgar sér takmarkað náttúrulega með fræplöntum. Fræið berst stutt frá
      vaxtarstað (þungt fræ).
    </p>
    <p>
      Borðviðarnýtingarhlutfall er fremur lágt en bolir nýtast ágætlega í iðnvið
      og kurl. Borðviður er endingargóður og slitsterkur og því mjög verðmætur.
    </p>
  </Layout>
)

export default LarixPage
